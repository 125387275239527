import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Button, IconButton, Avatar, Box, Drawer, List, ListItem, ListItemText, Menu, MenuItem, CssBaseline } from '@mui/material';
import ContactsIcon from '@mui/icons-material/Contacts';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import SettingsIcon from '@mui/icons-material/Settings';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import PestimaLogo from './img/Pestima.png';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useNavigate } from 'react-router-dom';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import InventoryIcon from '@mui/icons-material/Inventory';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import BoltIcon from '@mui/icons-material/Bolt';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ApprovalIcon from '@mui/icons-material/Approval';
import BorderAllIcon from '@mui/icons-material/BorderAll';
import DescriptionIcon from '@mui/icons-material/Description';
const drawerWidth = 240;

function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElC, setAnchorElC] = useState(null);
  const [user, setuser] = useState([]);
  const [isclient, setisclient] = useState();
  const navigate = useNavigate();
  const menuItems = {
    'Utilisateurs': ['Option', 'Option', 'Option'],
    'Produit': ['Option', 'Option'],
    'Options': ['Option', 'Option', 'Option', 'Option'],
    'Planing': ['FCT', 'FCP']
  };

  const menuIcons = {
    'Utilisateurs': <ContactsIcon />,
    'Stock': <Inventory2Icon />,
    'Produit': <AccountBalanceWalletIcon />,
    'Options': <SettingsIcon />,
    'Planing': <CalendarMonthIcon />,
    'Secteurs': <DescriptionIcon />
  };

  const handleMenuClick = (menu) => {
    if (menu === currentMenu) {
      setDrawerOpen(!drawerOpen); // Toggle Drawer state
    } else {
      setCurrentMenu(menu);
      setDrawerOpen(true);
    }
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
    setCurrentMenu(null);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAvatarClose = () => {
    setAnchorEl(null);
  };


  const handleCClick = (event) => {
    setAnchorElC(event.currentTarget);
  };

  const handleCClose = () => {
    setAnchorElC(null);
  };

  const handleProfileClick = () => {
    // Handle profile click action
    setAnchorEl(null); // Close the menu after action
  };

  const handleLogoutClick = () => {
    localStorage.setItem("isloged", false);
    localStorage.removeItem("UserData");
    setAnchorEl(null);
    navigate("/"); // Close the menu after action
  };

  const bgColor = '#930e0e';
  const textColor = '#ffffff';
  const fontFamily = 'Roboto, sans-serif';

  useEffect(() => {
    const isClinet = JSON.parse(localStorage.getItem("isClinet"));
    setisclient(isClinet);
    const UserData = JSON.parse(localStorage.getItem("UserData"));
    setuser(UserData);
  }, []);


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: bgColor, zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={PestimaLogo} alt="PESTIMA Logo" style={{ height: '25px' }} />
            <Box sx={{ display: 'flex', ml: 2 }}>
              {
                !isclient ?
                <Button size="small" onClick={() => navigate("/Dashboard")} sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}>
                  <AssessmentIcon />Dashboard
                </Button>

                :

                <Button size="small" onClick={() => navigate("/ClientPage")} sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}>
                  <AssessmentIcon />Dashboard
                </Button>
              }

              {parseInt(user.id_role) === 1 ?
                <>
                  <Button
                    size="small"
                    onClick={() => navigate("/UserTable")}
                    startIcon={<ContactsIcon />}
                    sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}
                  >
                    Utilisateurs
                  </Button>
                  <Box>
                    <Button
                      onClick={handleCClick}
                      startIcon={<AssignmentIndIcon />}
                      size="small"
                      sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}
                    >
                      Clients
                    </Button>
                    <Menu
                      id="avatar-menu"
                      anchorEl={anchorElC}
                      open={Boolean(anchorElC)}
                      onClose={handleCClose}
                    >
                      <MenuItem onClick={() => navigate('/ClientPersonneTable')}>Personne</MenuItem>
                      <MenuItem onClick={() => navigate('/ClientEntrepriseTable')}>Entreprise</MenuItem>
                    </Menu>
                  </Box>

                  <Button
                    onClick={() => navigate("/ProduitTable")}
                    startIcon={<InventoryIcon />}
                    sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}
                    size="small"
                  >
                    Produits
                  </Button>
                  <Button
                    onClick={() => navigate("/StockTable")}
                    startIcon={<ShoppingCartIcon />}
                    sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}
                    size="small"
                  >
                    Stock
                  </Button>
                  <Button
                    onClick={() => navigate("/MatiereActiveTable")}
                    startIcon={<BoltIcon />}
                    sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1, textWrap: "nowrap" }}
                    size="small"
                  >
                    Matieres Avctives
                  </Button>
                  <Button
                    onClick={() => navigate("/ConventionTable")}
                    startIcon={<ApprovalIcon />}
                    sx={{ color: textColor, fontFamily: fontFamily, mr: 1, textTransform: 'none' }}
                    size="small"
                  >
                    Convention
                  </Button>
                  <Button
                    onClick={() => navigate("/listPlan")}
                    startIcon={<BorderAllIcon />}
                    sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}
                    size="small"
                  >
                    Plans
                  </Button>
                  <Button
                    onClick={() => navigate("/SecteurTable")}
                    startIcon={<DescriptionIcon />}
                    sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}
                    size="small"
                  >
                    Secteurs
                  </Button>
                </>
                :
                null
              }
              <Button
                onClick={() => navigate("/CalendarPage")}
                startIcon={<DateRangeIcon />}
                sx={{ color: textColor, fontFamily: fontFamily, textTransform: 'none', mr: 1 }}
                size="small"
              >
                Planing
              </Button>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={handleAvatarClick} size="small">
              <Avatar alt="User Avatar" src="/path-to-user-image.jpg" sx={{ width: 24, height: 24 }} />
            </IconButton>
            <Menu
              id="avatar-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleAvatarClose}
            >
              <MenuItem onClick={handleLogoutClick}>se déconnecter</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, p: 3, zIndex: 1 }}>
        <Toolbar />
      </Box>
    </Box>
  );
}

export default Navbar;