import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Login from "./Components/Login";
import UserSetting from "./Components/UserSettings.js";
import Dashboard from "./Components/Dashboard.js";
import AddClient from "./Components/AddClient.js";
import ClientTable from "./Components/ClientTable.js";
import Navbar from "./Components/Navbar.js";
// import ProduitTable from "./Components/ProduitTableOld.js";
import ProduitTable from "./Components/ProduitTable.js";
import AddProduct from "./Components/AddProduit.js";
import UserTable from "./Components/UserTable.js";
import AddUser from "./Components/AddUser.js";
import AddProductProd from "./Components/AddProductDoc.js";
import AddClientDocument from "./Components/AddClientDoc.js";
import ProdDocumentTable from "./Components/ProductDocTable.js";
import DocumentClientTable from "./Components/ClientDocTable.js";
import CalendarPage from "./Components/CalendarPage.js";
import AuditTable from "./Components/CheckListControl.js";
import DashboardClient from "./Components/DashboardClient.js";
import DashboardTech from "./Components/DashboardTechne.js";
import DataTable from "./Components/PostePiegesControle.js";
import UpdateUser from "./Components/UpdateUser.js";
import UpdateClient from "./Components/UpdateClient.js";
import UpdateProduct from "./Components/UpdateProduct.js";
import StockTable from "./Components/StockTable.js";
import AddStock from "./Components/AddStock.js";
import UpdateStock from "./Components/UpdateStock.js";
import MatierActivesTable from "./Components/MatiersActivesTable.js";
import AddMatierActive from "./Components/AddMatiersActive.js";
import UpdateMatiereActive from "./Components/UpdateMatiereActive.js";
import ConventionTable from "./Components/ConventionTable.js";
import AddConvention from "./Components/AddConvention.js";
import UpdateConvention from "./Components/UpdateConvention.js";
import Plan from "./Components/Plan.js";
import ListePlan from './Components/ListePlans.js'
import TableFct from "./Components/TableFct.js";
import TableFcp from "./Components/TableFcp.js";
import PassageTable from "./Components/PassageTable.js";
import AddPost from "./Components/AddPost.js";
import ClientEntrepriseTable from "./Components/ClientEntrepriseTable.js";
import AddClientEntreprise from "./Components/AddClientEntreprise.js";
import ClientPersonneTable from "./Components/ClientPersonneTable.js";
import AddClientPersonne from "./Components/AddClientPersonne.js";
import AuditForm from "./Components/AuditForm.js";
import UpdateClientPersonne from "./Components/UpdateClientPersonne.js";
import UpdateClientEntreprise from "./Components/UpdateClientEntreprise.js";
import ClientPage from "./Components/ClientPage.js";
import Accueil from "./Components/AccueilC.js";
import Passages from "./Components/PassagesC.js";
import ContactUs from "./Components/ContactUsC.js";
import StatictiquesC from "./Components/StatictiquesC.js";
import Mesures from "./Components/Mesures.js";
import SecteurTable from "./Components/SecteurTable.js";
import AddSecteur from "./Components/AddSecteur.js";
import UpdateSecteur from "./Components/UpdateSecteurs.js";



function App() {
  return (
      
      <Routes>
        <Route path="/fcp/:idpassage" element={<TableFcp/>} />
        <Route path="/fct/:idpassage" element={<TableFct/>}/>
        <Route path="/PassageTable" element={<PassageTable/>} />
        <Route path="/Addpost" element={<AddPost/>} />
        <Route path="/listPlan" element={<ListePlan/>} />
        <Route path="/plan/:idPlan" element={<Plan/>}/>
         <Route path="/AuditTable/:idpassage" element={<AuditTable/>} />
         <Route path="/UdpateUser/:id" element={<UpdateUser/>} />
         <Route path="/UserTable" element={<UserTable/>} />
         {/* <Route path="/DataTable" element={<DataTable/>} /> */}
         <Route path="/DashboardTech" element={<DashboardTech/>} />
         <Route path="/DashboardClient" element={<DashboardClient/>} />
         <Route path="/CalendarPage" element={<CalendarPage/>} />
         <Route path="/ProdDocumentTable" element={<ProdDocumentTable/>} />
         <Route path="/DocumentClientTable" element={<DocumentClientTable/>} />
         <Route path="/AddClientDocument" element={<AddClientDocument/>} />
         <Route path="/AddProductProd" element={<AddProductProd/>} />
         <Route path="/AddUser" element={<AddUser/>} />
         <Route path="/ClientEntrepriseTable" element={<ClientEntrepriseTable/>}/>
         <Route path="/ClientPersonneTable" element={<ClientPersonneTable/>}/>
         <Route path="/UpdateClient/:id" element={<UpdateClient/>} />
         <Route path="/AddProduct" element={<AddProduct/>} />
         <Route path="/ProduitTable" element={<ProduitTable/>} />
         <Route path="/UdpateProduits/:id" element={<UpdateProduct/>} />
         <Route path="/Navbar" element={<Navbar/>} />
         <Route path="/AddClientEntreprise" element={<AddClientEntreprise/>} />
         <Route path="/UpdateClientEntreprise/:id" element={<UpdateClientEntreprise/>} />
         <Route path="/AddClientPersonne" element={<AddClientPersonne/>} />
         <Route path="/UpdateClientPersonne/:id" element={<UpdateClientPersonne/>} />
         <Route path="/" element={<Login/>} />
         <Route path="/Dashboard" element={<Dashboard/>} />
         <Route path="/UserSetting" element={<UserSetting/>} />
         <Route path="/StockTable" element={<StockTable/>}/>
         <Route path="/AddStock" element={<AddStock/>}/>
         <Route path="/UpdateStock/:id" element={<UpdateStock/>}/>
         <Route path="/MatiereActiveTable" element={<MatierActivesTable/>}/>
         <Route path="/AddMatiereActive" element={<AddMatierActive/>}/>
         <Route path="/UpdateMatiereActive/:id" element={<UpdateMatiereActive/>}/>
         <Route path="/ConventionTable" element={<ConventionTable/>}/>
         <Route path="/AddConvention" element={<AddConvention/>}/>
         <Route path="/AuditForm/:idpassage" element={<AuditForm/>}/>
         <Route path="/UpdateConvention/:id" element={<UpdateConvention/>}/>
         <Route path="/ClientPage" element={<ClientPage/>}/>
         <Route path="/Acceuil" element={<Accueil/>} />
         <Route path="/Passages" element={<Passages/>} />
         <Route path="/ContactUs" element={<ContactUs/>} />
         <Route path="/Statictiques" element={<StatictiquesC/>} />
         <Route path="/Mesures" element={<Mesures/>} />

         <Route path="/SecteurTable" element={<SecteurTable/>} />
         <Route path="/AddSecteur" element={<AddSecteur/>} />
         <Route path="/UpdateSecteur/:id" element={<UpdateSecteur/>} />
      </Routes>

  );
}

export default App;
