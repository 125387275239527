import React, { useEffect, useState } from 'react';
import { Box, TextField, Typography, Button, IconButton } from '@mui/material';
import { Add , Remove } from '@mui/icons-material';
import Navbar from './Navbar.js';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

const formatDate = (newdate = new Date()) => {
    const date = new Date(newdate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  

function AuditForm() {
    const [questions, setQuestions] = useState([{ major: '', minors: [''] }]);
    const navigate = useNavigate()
    const {idpassage} = useParams()
    const [isfirst , setisfirst] = useState(false)
    useEffect(()=>{
        axios.get('https://serv.pestima.yuliagroupe.com/api/audits/passage/' + idpassage)
        .then((res) => {
            if(res.data.length === 0){
              setisfirst(true)
            }
            else if(res.data[0].categorie.length === 0){
              setQuestions([{ major: '', minors: [''] }])
            }
            else{
                const formattedQuestions = res.data[0].categorie.map((category) => ({
                    major: category.Category,
                    minors: category.questions.map((question) => question.Question),
                  }));
                  setQuestions(formattedQuestions);
            }


        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    },[])


    const handlesubmit = () => {
        if (isfirst) {
          axios.post('https://serv.pestima.yuliagroupe.com/api/audits', { Date: formatDate() , id_passage : idpassage })
            .then((res) => {
              questions.map((q) => {
                axios.post('https://serv.pestima.yuliagroupe.com/api/categories', { ID_Audit: res.data.ID_Audit, Category: q.major })
                  .then((res) => {
                    q.minors.map((qm) => {
                      axios.post('https://serv.pestima.yuliagroupe.com/api/questions', { Question: qm, ID_Category: res.data.ID_Category })
                        .then((res) => {
                          console.log(res.data);
                          setTimeout(() => {
                            navigate('/AuditTable/' + idpassage);
                          }, 3000);
                        });
                    });
                  });
              });
            });
        } else {
    axios.get('https://serv.pestima.yuliagroupe.com/api/audits/passage/' + idpassage)
      .then((res) => {
        const existingCategories = res.data[0].categorie;

        questions.forEach((newCategory, index) => {
          const existingCategory = existingCategories[index];

          if (existingCategory) {
            // Update existing category if necessary
            if (existingCategory.Category !== newCategory.major) {
              axios.put('https://serv.pestima.yuliagroupe.com/api/categories/' + existingCategory.ID_Category, { Category: newCategory.major });
            }

            // Update or delete questions
            existingCategory.questions.forEach((existingQuestion, minorIndex) => {
              if (newCategory.minors[minorIndex]) {
                // Update existing question if it has changed
                if (existingQuestion.Question !== newCategory.minors[minorIndex]) {
                  axios.put('https://serv.pestima.yuliagroupe.com/api/questions/' + existingQuestion.ID_Question, { Question: newCategory.minors[minorIndex] });
                }
              } else {
                // If the question was deleted, send a DELETE request
                axios.delete('https://serv.pestima.yuliagroupe.com/api/questions/' + existingQuestion.ID_Question);
              }
            });

            // Add any new minor questions
            if (newCategory.minors.length > existingCategory.questions.length) {
              newCategory.minors.slice(existingCategory.questions.length).forEach((newQuestion) => {
                axios.post('https://serv.pestima.yuliagroupe.com/api/questions', { Question: newQuestion, ID_Category: existingCategory.ID_Category });
              });
            }
          } else {
            // Create new category and its questions
            axios.post('https://serv.pestima.yuliagroupe.com/api/categories', { ID_Audit: res.data[0].ID_Audit, Category: newCategory.major })
              .then((res) => {
                newCategory.minors.forEach((newQuestion) => {
                  axios.post('https://serv.pestima.yuliagroupe.com/api/questions', { Question: newQuestion, ID_Category: res.data.ID_Category });
                });
              });
          }
        });

        // Handle deleted categories
        existingCategories.slice(questions.length).forEach((existingCategory) => {
          axios.delete('https://serv.pestima.yuliagroupe.com/api/categories/' + existingCategory.ID_Category);
        });

        setTimeout(() => {
          navigate('/AuditTable/' + idpassage);
        }, 3000);
      });
  }
      };
      

    const fontFamily = 'Roboto, sans-serif';

    const inputStyles = {
        backgroundColor: '#f2f3f3',
        '& .MuiOutlinedInput-root': {
            borderRadius: '5px',
            height: '35px',
            '& fieldset': {
                borderColor: '#C0C0C0',
            },
            '&:hover fieldset': {
                borderColor: '#930e0e',
            },
            '&.Mui-focused fieldset': {
                borderColor: '#930e0e',
            },
        },
        '& label.Mui-focused': {
            color: '#930e0e',
            fontWeight: 'bold',
        },
    };

    const handleAddQuestionSet = () => {
        setQuestions([...questions, { major: '', minors: [''] }]);
    };

    const handleAddMinorQuestion = (index) => {
        const newQuestions = [...questions];
        newQuestions[index].minors.push('');
        setQuestions(newQuestions);
    };

    const handleQuestionChange = (index, value, type, minorIndex = null) => {
        const newQuestions = [...questions];
        if (type === 'major') {
            newQuestions[index].major = value;
        } else if (type === 'minor') {
            newQuestions[index].minors[minorIndex] = value;
        }
        setQuestions(newQuestions);
    };

    const handleDeleteMajor = (index) => {
        const newQuestions = [...questions];
        newQuestions.splice(index, 1); // Remove the major question and its minors
        setQuestions(newQuestions);
      };

      
      const handleDeleteMinor = (index, minorIndex) => {
        const newQuestions = [...questions];
        newQuestions[index].minors.splice(minorIndex, 1); // Remove the specific minor question
        setQuestions(newQuestions);
      };
      

    return (
        <Box>
            <Navbar />
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Typography variant="h5" sx={{ fontWeight: "bold" }} gutterBottom>Audit Form</Typography>
                    <Box
                        sx={{
                            maxWidth: '100%',
                            mx: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mt: 5,
                            fontFamily: fontFamily,
                            padding: '30px',
                            borderRadius: '5px',
                            boxShadow: 'none',
                            border: '0.5px solid #C0C0C0',
                            '& > :not(style)': { mb: 2 },
                        }}
                    >
{questions.map((questionSet, index) => (
  <Box key={index} sx={{ width: '100%', mt: index > 0 ? 4 : 0 }}>
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center' }}>
      <Typography sx={{ fontWeight: 'bold' }}>Question Major {index + 1}</Typography>
      <TextField
        required
        fullWidth
        value={questionSet.major}
        onChange={(e) => handleQuestionChange(index, e.target.value, 'major')}
        id={`questionMajor-${index}`}
        name={`questionMajor-${index}`}
        variant="outlined"
        sx={inputStyles}
        InputProps={{
          endAdornment: (
            <>
              {index === questions.length - 1 && (
                <IconButton onClick={handleAddQuestionSet}>
                  <Add />
                </IconButton>
              )}
              <IconButton onClick={() => handleDeleteMajor(index)}>
                <Remove /> {/* Delete major question */}
              </IconButton>
            </>
          ),
        }}
      />
    </Box>
    {questionSet.minors.map((minorQuestion, minorIndex) => (
      <Box key={minorIndex} sx={{ width: '100%', mt: 2, display: 'flex', alignItems: 'center' }}>
        <Typography sx={{ fontWeight: 'bold' }}>Question Minor {minorIndex + 1}</Typography>
        <TextField
          required
          fullWidth
          value={minorQuestion}
          onChange={(e) => handleQuestionChange(index, e.target.value, 'minor', minorIndex)}
          id={`questionMinor-${index}-${minorIndex}`}
          name={`questionMinor-${index}-${minorIndex}`}
          variant="outlined"
          sx={inputStyles}
          InputProps={{
            endAdornment: (
              <>
                {minorIndex === questionSet.minors.length - 1 && (
                  <IconButton onClick={() => handleAddMinorQuestion(index)}>
                    <Add />
                  </IconButton>
                )}
                <IconButton onClick={() => handleDeleteMinor(index, minorIndex)}>
                  <Remove /> {/* Delete minor question */}
                </IconButton>
              </>
            ),
          }}
        />
      </Box>
    ))}
  </Box>
))}

                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', mt: 4 }}>
                            <Button variant="contained" sx={{
                                mb: 2, bgcolor: '#930e0e', borderRadius: '9px', fontFamily: 'Roboto',
                                '&:hover': { bgcolor: '#a9281e' },
                                color: 'white',
                                textTransform: 'none',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '15%',
                                alignSelf: 'flex-end'
                            }} 
                            onClick={handlesubmit}
                            >
                                Submit
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default AuditForm;
