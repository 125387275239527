import React, { useEffect, useState,useRef } from 'react';
import Navbar from './Navbar';
import { Calendar, momentLocalizer } from 'react-big-calendar';

import 'moment/locale/fr';
import 'react-big-calendar/lib/css/react-big-calendar.css';

import divider from './img/divider.png'
import cacher from "./img/cacher.png"

import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Toolbar,
    Typography,
    Autocomplete,
     Checkbox, 
     FormControlLabel,
     Snackbar,
     Alert
} from '@mui/material';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import axios from 'axios';
import moment from 'moment';
import ConfirmationDialog from './ConfirmationDialog';
import { useNavigate } from 'react-router-dom';
const fontFamily = 'Roboto, sans-serif';
moment.locale('fr');
const localizer = momentLocalizer(moment);

const inputStyles = {
    backgroundColor: '#f2f3f3',
    mb: 2,
    height: '45px',
    borderRadius: '5px',
    width: '100%',
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#C0C0C0',
            height: "90%",
        },
        '&:hover fieldset': {
            borderColor: '#930e0e',
        },
        '&.Mui-focused fieldset': {
            borderColor: '#930e0e',
        },
    },
    '& label.Mui-focused': {
        color: '#930e0e',
        fontWeight: 'bold',
    },
};

const ButtonStyles = {
    mb: 2,
    bgcolor: '#930E0E',
    borderRadius: '5px',
    fontFamily: 'Roboto',
    '&:hover': { bgcolor: '#a9281e' },
    color: 'white',
    textTransform: 'none',
    width: '15%',
};

const formatDate = (date = new Date()) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

function CalendarPage({called = false}) {
    const [date, setDate] = useState(new Date());
    const certificateRef = useRef();
    const [view, setView] = useState('month');
    const [data, setData] = useState([]);
    const [ProduisPost , setProduisPost] = useState('');
    const [ ProduisPiege , setProduisPiege ] = useState('')
    const [openDialog, setOpenDialog] = useState(false);
    const [intervention, setIntervention] = useState("");
    const [interventions, setInterventions] = useState([]);
    const [debut, setDebut] = useState("");
    const [fin, setFin] = useState("");
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [produits,setproduits] = useState([])
// new updates

const [isclient , setisclient] = useState()

const [open ,setOpen] = useState(false)
const [opener ,setOpener] = useState(false)
const [message , setmessage ] = useState([])

const [errorMessages, setErrorMessages] = useState([]);

const [selectedPosts, setSelectedPosts] = useState([]);
const [selectedPieges, setSelectedPieges] = useState([]);

const [selectedDesinsectisations, setselectedDesinsectisations] = useState([]);
const [selectedDeratisations, setselectedDeratisation] = useState([]);
const [selectedDeraptilisation, setselectedDeraptilisation] = useState([]);

const [posts, setPosts] = useState([]);
const [piege, setpiege] = useState(['Piece1', 'Piece2']);

const [ended , setended] = useState(false)


const [derInstaler, setderInstaler] = useState();
const [desInstaler, setdesInstaler] = useState();
const [deraInstaler , setderaInstaler] = useState()

const [user , setuser] = useState([])


const [validite, setValidite] = useState('');
const [secteursVisites, setSecteursVisites] = useState([]);
const [superviseur, setSuperviseur] = useState([]);
const [technicien, setTechnicien] = useState([]);
const [champsIntervention, setChampsIntervention] = useState('');

const [superviseurs, setSuperviseurs] = useState([]);
const [techniciens, setTechniciens] = useState([]);
const [secteurs,setsecteurs] = useState([])

const [dialogOpen, setDialogOpen] = useState(false);

const navigate = useNavigate()

// end of updates


const handleCloseer = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpener(false);
};

const handleClose = (event, reason) => {
  if (reason === 'clickaway') {
    return;
  }

  setOpen(false);
};


    const getCalenderdate = (isClinet,UserData) => {
      let url 
      if(isClinet){
        url = 'https://serv.pestima.yuliagroupe.com/api/passages/client/'+UserData.id_client
      }else{
        url = 'https://serv.pestima.yuliagroupe.com/api/passages'
      }
        axios.get(url)
            .then((res) => {
                const transformedData = res.data.map(passage => ({
                    id: passage.id_passage,
                    title: `${passage.convention.client.entreprises[0] ? passage.convention.client.entreprises[0].Nom : passage.convention.client.personnes[0].Nom}`,
                    start: new Date(passage.date_debut),
                    end: new Date(passage.date_fin),
                    intervention: passage.convention,
                    produits : passage.produits_a_utilisees,
                    ended : passage.ended,
                    validite:passage.Validee,
                    techniciens: passage.execute_pars_user.filter(tech => parseInt(tech.id_role) === 2),
                    superviseur: passage.execute_pars_user.filter(tech => parseInt(tech.id_role) === 1),
                    secteurs : passage.secteur,
                    champs:passage.champs
                }));
                console.log(transformedData)
                setData(transformedData);


            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getInterventions = () => {
        axios.get('https://serv.pestima.yuliagroupe.com/api/conventions')
            .then((res) => {
                setInterventions(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getProducts = () => {
        axios.get('https://serv.pestima.yuliagroupe.com/api/produits')
            .then((res) => {
                const products = res.data.map(produit => ({
                    id: produit.id_produits,
                    nom: produit.nom_produits,
                    matiereActive:produit.matieres_actives,
                    ORGANISME_CIBLE : produit.ORGANISME_CIBLE
                }));
                setproduits(products)
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const getPosts = ()=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/posts")
        .then((res)=>{
          setPosts(res.data)
        })
      }

      const getPieges = ()=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/pieges")
        .then((res)=>{
          setpiege(res.data)
        })
      }

      const getSecteurs = () =>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/secteurs")
        .then((res)=>{
          setsecteurs(res.data)
        })
      }

      const getUsers = () => {
        axios.get("https://serv.pestima.yuliagroupe.com/api/users")
          .then((res) => {
            // Filter and set superviseurs (id_role === 1)
            const superviseurs = res.data.filter(user => parseInt(user.id_role) === 1);
            setSuperviseurs(superviseurs);
            
            // Filter and set techniciens (id_role === 2)
            const techniciens = res.data.filter(user => parseInt(user.id_role) === 2);
            setTechniciens(techniciens);
          });
      };
      
    useEffect(() => {
        const UserData = JSON.parse(localStorage.getItem("UserData"))
        setuser(UserData)
        const isClinet = JSON.parse(localStorage.getItem("isClinet"))
        setisclient(isClinet)
        getCalenderdate(isClinet,UserData);
        getInterventions();
        getProducts();
        getPosts()
        getPieges()
        getSecteurs()
        getUsers()
    }, []);


    const getInfoCount = (pr) =>{
      pr.produits.map((p)=>{
        axios.get("https://serv.pestima.yuliagroupe.com/api/pieges/count20/"+p.id_produits+'/'+pr.id)
        .then((res)=>{
          setdesInstaler((prevState) =>
            prevState.map((item) =>
              item.products === p.id_produits
                ? { ...item, num20W: res.data.count }
                : item
            )
          );
        })

        axios.get("https://serv.pestima.yuliagroupe.com/api/pieges/count40/"+p.id_produits+'/'+pr.id)
        .then((res)=>{
          setdesInstaler((prevState) =>
            prevState.map((item) =>
              item.products === p.id_produits
                ? { ...item, num40W: res.data.count }
                : item
            )
          );
        })

        axios.get("https://serv.pestima.yuliagroupe.com/api/posts/countTrue/"+p.id_produits+'/'+pr.id)
        .then((res)=>{
          setderInstaler((prevState) =>
            prevState.map((item) =>
              item.products === p.id_produits
                ? { ...item, Interne: res.data.count }
                : item
            )
          );
        })


        axios.get("https://serv.pestima.yuliagroupe.com/api/posts/countFalse/"+p.id_produits+'/'+pr.id)
        .then((res)=>{
          setderInstaler((prevState) =>
            prevState.map((item) =>
              item.products === p.id_produits
                ? { ...item, Externe: res.data.count }
                : item
            )
          );
        })

        axios.get("https://serv.pestima.yuliagroupe.com/api/deraptilisationpassages/count/"+p.id_produits+'/'+pr.id)
        .then((res)=>{
          setderaInstaler((prevState) =>
            prevState.map((item) =>
              item.products === p.id_produits
                ? { ...item, num: res.data.count }
                : item
            )
          );
        })


      })

      
      
    }


    const handleNavigate = (newDate) => {
        setDate(newDate);
    };

    const handleViewChange = (newView) => {
        setView(newView);
    };

    const handleSelectSlot = ({ start, end }) => {
        setSelectedEvent(null);
        setDebut(moment(start).format('YYYY-MM-DDTHH:mm'));
        setFin(moment(end).format('YYYY-MM-DDTHH:mm'));
        setIntervention("");
        setended(false)
        setValidite("")
        setSecteursVisites([])
        setTechnicien([])
        setSuperviseur([])
        setselectedDeratisation([])
        setselectedDesinsectisations([])
        setselectedDeraptilisation([])
        setChampsIntervention("")
        setOpenDialog(true);
    };

    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
        setDebut(moment(event.start).format('YYYY-MM-DDTHH:mm'));
        setFin(moment(event.end).format('YYYY-MM-DDTHH:mm'));
        setIntervention(event.intervention.id_Convention);
        setSelectedPosts(event.posts)
        setSelectedPieges(event.pieges)
        getInfoCount(event)
        setOpenDialog(true);
        setended(event.ended)
        setValidite(event.validite)
        setSecteursVisites(event.secteurs)
        setChampsIntervention(event.champs)
        setTechnicien(event.techniciens)
        setSuperviseur(event.superviseur)
        const productsderap = event.produits
        .filter(produit => produit.produit.ORGANISME_CIBLE === 'Déraptilisation')
        .map(produit => ({
          id: produit.produit.id_produits,
          nom: produit.produit.nom_produits,
          matiereActive: produit.produit.matieres_actives,
          ORGANISME_CIBLE: produit.ORGANISME_CIBLE
        }));
        setselectedDeraptilisation(productsderap)
        setderaInstaler(productsderap.map((pr) => ({ products: pr.id, num: 0 })))
        const productsderat = event.produits
        .filter(produit => produit.produit.ORGANISME_CIBLE === 'Dératisation')
        .map(produit => ({
          id: produit.produit.id_produits,
          nom: produit.produit.nom_produits,
          matiereActive: produit.produit.matieres_actives,
          ORGANISME_CIBLE: produit.ORGANISME_CIBLE
        }));
        setselectedDeratisation(productsderat)
        setderInstaler(  productsderat.map((pr) => ({ products:pr.id , Externe: 0 , Interne :0  })))
        
        const productsdesi = event.produits
        .filter(produit => produit.produit.ORGANISME_CIBLE === 'Désinsectisation')
        .map(produit => ({
          id: produit.produit.id_produits,
          nom: produit.produit.nom_produits,
          matiereActive: produit.produit.matieres_actives,
          ORGANISME_CIBLE: produit.ORGANISME_CIBLE
        }));
        setselectedDesinsectisations(productsdesi)
        setdesInstaler(productsdesi.map((pr) => ({ products:pr.id ,num20W: 0, num40W: 0 })))
    };

    const handleDialogClose = () => {
        setOpenDialog(false);
        setDebut("");
        setFin("");
        setIntervention("");
        setProduisPost("")
        setProduisPiege("")
    };


    const getStock = async (id) => {
      const res = await axios.get("https://serv.pestima.yuliagroupe.com/api/stocks/search/" + id);
      return res.data; // Return the data so it can be used in the map
    };
    


    const handleConfirm = () => {
      


      const errorMessages = [];

      const checkStockAvailability = async () => {
        for (const pr of desInstaler) {
          const data = await getStock(pr.products);
          if (data.stocks.length < (parseInt(pr.num20W) + parseInt(pr.num40W))) {
            errorMessages.push(`Stock insufficient for ${data.stocks[0] ? data.stocks[0].produit.nom_produits : data.product_name} (${data.stocks.length} Max)`);
          }
        }
  
        for (const pr of derInstaler) {
          const data = await getStock(pr.products);
          if (data.stocks.length < (parseInt(pr.Interne) + parseInt(pr.Externe))) {
            errorMessages.push(`Stock insufficient for ${data.stocks[0] ? data.stocks[0].produit.nom_produits : data.product_name} (${data.stocks.length} Max)`);
          }
        }
  
        for (const pr of deraInstaler) {
          const data = await getStock(pr.products);
          if (data.stocks.length < parseInt(pr.num)) {
            errorMessages.push(`Stock insufficient for ${data.stocks[0] ? data.stocks[0].produit.nom_produits : data.product_name} (${data.stocks.length} Max)`);
          }
        }
      };

      checkStockAvailability().then(() => {
        if (errorMessages.length === 0) {
// Handling desInstaler
desInstaler.map(async (pr) => {
const data = await getStock(pr.products);
let counter = 0;

// Handle 20W
for (let i = 0; i < pr.num20W; i++) {
  try {
    const currentStock = data.stocks[counter];

    await axios.post("https://serv.pestima.yuliagroupe.com/api/pieges", {
      ID_Etat: 1,
      ID_Stock: currentStock.ID_Stock,
      id_passage: selectedEvent.id,
      Code_Ref: `${currentStock.ID_Stock}/${selectedEvent.id}`,
      Actif: 1,
      Watt: 20,
      INTERNE: 0
    });

    await axios.put(`https://serv.pestima.yuliagroupe.com/api/stocks/${currentStock.ID_Stock}`, { Used: 1 });

    counter++;
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

// Handle 40W
for (let i = 0; i < pr.num40W; i++) {
  try {
    const currentStock = data.stocks[counter];

    await axios.post("https://serv.pestima.yuliagroupe.com/api/pieges", {
      ID_Etat: 1,
      ID_Stock: currentStock.ID_Stock,
      id_passage: selectedEvent.id,
      Code_Ref: `${currentStock.ID_Stock}/${selectedEvent.id}`,
      Actif: 1,
      Watt: 40,
      INTERNE: 0
    });

    await axios.put(`https://serv.pestima.yuliagroupe.com/api/stocks/${currentStock.ID_Stock}`, { Used: 1 });

    counter++;
  } catch (error) {
    console.error('An error occurred:', error);
  }
}
});

// Handling derInstaler
derInstaler.map(async (pr) => {
const data = await getStock(pr.products);
let counter = 0;

// Handle Interne
for (let i = 0; i < pr.Interne; i++) {
  try {
    const currentStock = data.stocks[counter];

    await axios.post("https://serv.pestima.yuliagroupe.com/api/posts", {
      ID_Etat: 1,
      ID_Stock: currentStock.ID_Stock,
      id_passage: selectedEvent.id,
      Code_Ref: `${currentStock.ID_Stock}/${selectedEvent.id}`,
      Actif: 1,
      INTERNE: 1
    });

    await axios.put(`https://serv.pestima.yuliagroupe.com/api/stocks/${currentStock.ID_Stock}`, { Used: 1 });

    counter++;
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

// Handle Externe
for (let i = 0; i < pr.Externe; i++) {
  try {
    const currentStock = data.stocks[counter];

    await axios.post("https://serv.pestima.yuliagroupe.com/api/posts", {
      ID_Etat: 1,
      ID_Stock: currentStock.ID_Stock,
      id_passage: selectedEvent.id,
      Code_Ref: `${currentStock.ID_Stock}/${selectedEvent.id}`,
      Actif: 1,
      INTERNE: 0
    });

    await axios.put(`https://serv.pestima.yuliagroupe.com/api/stocks/${currentStock.ID_Stock}`, { Used: 1 });

    counter++;
  } catch (error) {
    console.error('An error occurred:', error);
  }
}
});

// Handling deraInstaler
deraInstaler.map(async (pr) => {
const data = await getStock(pr.products);
let counter = 0;

for (let i = 0; i < pr.num; i++) {
  try {
    const currentStock = data.stocks[counter];

    await axios.post("https://serv.pestima.yuliagroupe.com/api/deraptilisationpassages", {
      ID_Stock: currentStock.ID_Stock,
      id_passage: selectedEvent.id,
    });

    await axios.put(`https://serv.pestima.yuliagroupe.com/api/stocks/${currentStock.ID_Stock}`, { Used: 1 });

    counter++;
  } catch (error) {
    console.error('An error occurred:', error);
  }
}
});
  axios.put(`https://serv.pestima.yuliagroupe.com/api/passages/${selectedEvent.id}`, { ended: 1 });
  getCalenderdate(isclient,user);
  getInterventions();
  getProducts();
  getPosts()
  getPieges()
  handleDialogClose()
        } else {
          setErrorMessages(errorMessages);
          setOpener(true);
        }
      })

      setDialogOpen(false);
    };


    const handleCloseDialog = () => {
      setDialogOpen(false);
    };



    const handleSaveEvent = () => {
      if(parseInt(user.id_role) === 2){
        setDialogOpen(true);
      }else if(parseInt(user.id_role) === 1){
        const newEvent = {
          date_debut: debut,
          date_fin: fin,
          id_Convention: intervention,
          ID_User : 1 ,
          Validee : validite,
          champs : champsIntervention
      };

      if (selectedEvent) {
        axios.put(`https://serv.pestima.yuliagroupe.com/api/passages/${selectedEvent.id}`, newEvent)
        .then((res) => {
          const passageId = res.data.id_passage;
      
          // Step 1: Delete old produitsAUtilisees
          axios.delete(`https://serv.pestima.yuliagroupe.com/api/produitsAUtilisees/passage/${passageId}`)
            .then(() => {
              // Step 2: Add new produitsAUtilisees
              const productPromises = selectedDeraptilisation.concat(selectedDesinsectisations, selectedDeratisations).map((data) => {
                return axios.post("https://serv.pestima.yuliagroupe.com/api/produitsautilisees", { id_produits: data.id, id_passage: passageId });
              });
      
              Promise.all(productPromises)
                .then(() => {
                  // Step 3: Delete old secteurpassage
                  axios.delete(`https://serv.pestima.yuliagroupe.com/api/secteurpassage/${passageId}`)
                    .then(() => {
                      // Step 4: Add new secteurpassage
                      const secteurPromises = secteursVisites.map((sec) => {
                        return axios.post(`https://serv.pestima.yuliagroupe.com/api/secteurpassage/${sec.ID_Secteur}/${passageId}`);
                      });
      
                      Promise.all(secteurPromises)
                        .then(() => {
                          // Step 5: Delete old executepar
                          axios.delete(`https://serv.pestima.yuliagroupe.com/api/executepar/${passageId}`)
                            .then(() => {
                              // Step 6: Add new executepar
                              const userPromises = technicien.concat(superviseur).map((user) => {
                                return axios.post(`https://serv.pestima.yuliagroupe.com/api/executepar/${user.ID_User}/${passageId}`);
                              });
      
                              Promise.all(userPromises)
                                .then(() => {
                                  // Close modal and refresh calendar only after all promises are resolved
                                  setOpenDialog(false);
                                  getCalenderdate();
                                })
                                .catch((error) => {
                                  console.error("Error posting executepar data", error);
                                });
                            })
                            .catch((error) => {
                              console.error("Error deleting executepar data", error);
                            });
                        })
                        .catch((error) => {
                          console.error("Error posting secteurpassage data", error);
                        });
                    })
                    .catch((error) => {
                      console.error("Error deleting secteurpassage data", error);
                    });
                })
                .catch((error) => {
                  console.error("Error posting produitsAUtilisees data", error);
                });
            })
            .catch((error) => {
              console.error("Error deleting produitsAUtilisees data", error);
            });
        })
        .catch((error) => {
          console.error("There was an error updating the event!", error);
        });
      
      } else {
        axios.post('https://serv.pestima.yuliagroupe.com/api/passages', newEvent)
        .then((res) => {
          const passageId = res.data.id_passage;
      
          // Step 1: Add produitsautilisees entries
          const productPromises = selectedDeraptilisation.concat(selectedDesinsectisations, selectedDeratisations).map((data) => {
            return axios.post("https://serv.pestima.yuliagroupe.com/api/produitsautilisees", { id_produits: data.id, id_passage: passageId });
          });
      
          // Step 2: Add secteurpassage entries
          const secteurPromises = secteursVisites.map((sec) => {
            return axios.post(`https://serv.pestima.yuliagroupe.com/api/secteurpassage/${sec.ID_Secteur}/${passageId}`);
          });
      
          // Step 3: Add executepar entries
          const userPromises = technicien.concat(superviseur).map((user) => {
            return axios.post(`https://serv.pestima.yuliagroupe.com/api/executepar/${user.ID_User}/${passageId}`);
          });
      
          // Use Promise.all to ensure all promises complete before closing the modal and updating the calendar
          Promise.all([...productPromises, ...secteurPromises, ...userPromises])
            .then(() => {
              // Close the modal and refresh the calendar after all requests are completed
              setOpenDialog(false);
              getCalenderdate();
            })
            .catch((error) => {
              console.error("There was an error adding the related entries!", error);
            });
        })
        .catch(error => {
          console.error("There was an error saving the event!", error);
        });
      
      }
      }


    };


    const CustomEvent = ({ event }) => (
        <span>{event.title}</span>
    );

    const handleDeleteEvent = () => {
        if (selectedEvent) {
            axios.delete(`https://serv.pestima.yuliagroupe.com/api/passages/${selectedEvent.id}`)
                .then(() => {
                    setOpenDialog(false);
                    getCalenderdate();
                })
                .catch(error => {
                    console.error("There was an error deleting the event!", error);
                });
        }
    };






    //pdf

    const downloadPdf = () => {
        const input = certificateRef.current;
        html2canvas(input, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [210, 297], // A4 size in landscape
          });
          pdf.addImage(imgData, 'PNG', 0, 0, 297, 210);
          pdf.save('certificate.pdf');
        });
      };
    
      const today = new Date();
      const formattedDate = formatDate(today);


    return (
        <>
        <Box width='100%'>
          {!called ? 
            <Navbar />
            :null}
            <Box sx={{ display: 'flex' }}>
                <Box component="main" sx={{ flexGrow: 1, p: 3, overflowX: 'hidden' }}>
                    <Box sx={{ width: '100%', overflowX: 'hidden', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      {!called ? 
                        <Typography variant="h5" gutterBottom component="div" sx={{ fontFamily: 'Roboto, sans-serif', width: '100%', mb: 2, fontWeight: 'bold' }}>
                            Calendrier des Passages
                        </Typography>
                        : null}
                        <Box sx={{ width: '100%', mx: "auto" }}>
                            <Toolbar>
                                <Button startIcon={<ArrowBackIos sx={{ color: '#930E0E' }} />} onClick={() => handleNavigate(moment(date).add(-1, view).toDate())} />
                                <Button endIcon={<ArrowForwardIos sx={{ color: '#930E0E' }} />} onClick={() => handleNavigate(moment(date).add(1, view).toDate())} />
                                <Typography variant="h6" sx={{ ml: 2, mr: 'auto', color: '#930E0E' }}>{moment(date).format('MMMM YYYY')}</Typography>
                            </Toolbar>
                            <Calendar
                                localizer={localizer}
                                events={data}
                                startAccessor="start"
                                endAccessor="end"
                                style={{ height: 500 }}
                                date={date}
                                onNavigate={handleNavigate}
                                onView={handleViewChange}
                                view={view}
                                selectable
                                onSelectSlot={parseInt(user.id_role) === 1 ? handleSelectSlot : null}
                                onSelectEvent={handleSelectEvent}
                                components={{ event: CustomEvent }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Dialog open={openDialog} onClose={handleDialogClose}>
                <DialogTitle>{parseInt(user.id_role) === 1 ? 'Planifier un Passage' : 'Affectuer les Instalations'}</DialogTitle>
                <DialogContent >
                  <Box paddingTop={2}>
                    <TextField
                        sx={inputStyles}
                        label="Date de début"
                        type="datetime-local"
                        value={debut}
                        onChange={(e) => setDebut(e.target.value)}
                        fullWidth
                        disabled = {ended ? true : parseInt(user.id_role) === 1 ? false : true}
                    />
                    <TextField
                        sx={inputStyles}
                        label="Date de fin"
                        type="datetime-local"
                        value={fin}
                        onChange={(e) => setFin(e.target.value)}
                        fullWidth
                        disabled = {ended ? true : parseInt(user.id_role) === 1 ? false : true}
                    />
                    <FormControl sx={{ width: '100%' }}>
                        <InputLabel>Conventions</InputLabel>
                        <Select
                            value={intervention}
                            onChange={(e) => setIntervention(e.target.value)}
                            fullWidth
                            sx={inputStyles}
                            disabled = {ended ? true : parseInt(user.id_role) === 1 ? false : true}
                        >
                            {interventions.map(interv => (
                                <MenuItem key={interv.id_Convention} value={interv.id_Convention}>
                                    {`${interv.client.entreprises[0] ? interv.client.entreprises[0].Nom : interv.client.personnes[0].Nom}`}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>


      {parseInt(user.id_role) === 1 ? 
<>
                  <Autocomplete
                            disabled={ended}
                              multiple
                              options={produits.filter(produit => produit.ORGANISME_CIBLE === "Dératisation")}
                              getOptionLabel={(option) =>  option.nom?.toString() || ''}
                              value={selectedDeratisations}
                              sx={inputStyles}
                              onChange={(event, value) => {
                                setselectedDeratisation(value);
                              }}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              renderInput={(params) => (
                                <TextField {...params} label="Dératisation" />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  {
                                    option.nom?.toString()
                                  }
                                </li>
                              )}
                            />

                      <Autocomplete
                      disabled={ended}
                              multiple
                              options={produits.filter(produit => produit.ORGANISME_CIBLE === "Désinsectisation")}
                              getOptionLabel={(option) =>  option.nom?.toString() || ''}
                              value={selectedDesinsectisations}
                              sx={inputStyles}
                              onChange={(event, value) => {
                                setselectedDesinsectisations(value);
                              }}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              renderInput={(params) => (
                                <TextField {...params} label="Désinsectisation" />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  {
                                    option.nom?.toString()
                                  }
                                </li>
                              )}
                            />


                          <Autocomplete
                          disabled={ended}
                              multiple
                              options={produits.filter(produit => produit.ORGANISME_CIBLE === "Déraptilisation")}
                              getOptionLabel={(option) =>  option.nom?.toString() || ''}
                              value={selectedDeraptilisation}
                              sx={inputStyles}
                              onChange={(event, value) => {
                                setselectedDeraptilisation(value);
                              }}
                              isOptionEqualToValue={(option, value) => option.id === value.id}
                              renderInput={(params) => (
                                <TextField {...params} label="Déraptilisation" />
                              )}
                              renderOption={(props, option) => (
                                <li {...props} key={option.id}>
                                  {
                                    option.nom?.toString()
                                  }
                                </li>
                              )}
                            />


                            <TextField
                                sx={inputStyles}
                                label="Champs d'intervention"
                                value={champsIntervention}
                                onChange={(e) => setChampsIntervention(e.target.value)}
                                fullWidth
                                disabled={ended}
                            />

                        <FormControl fullWidth sx={inputStyles}>
                            <InputLabel>Validité</InputLabel>
                            <Select
                                value={validite}
                                onChange={(e) => setValidite(e.target.value)}
                                fullWidth
                                disabled={ended}
                            >
                                <MenuItem value={30}>1 mois</MenuItem>
                                <MenuItem value={15}>15 jours</MenuItem>
                                <MenuItem value={7}>7 jours</MenuItem>
                            </Select>
                        </FormControl>

                        <Autocomplete
                              disabled={ended}
                              multiple
                              options={secteurs}
                              getOptionLabel={(option) => option.Secteur?.toString() || ''}
                              value={secteursVisites}
                              sx={inputStyles}
                              onChange={(event, value) => {
                                  setSecteursVisites(value);
                              }}
                              isOptionEqualToValue={(option, value) => option.ID_Secteur === value.ID_Secteur}
                              renderInput={(params) => (
                                  <TextField {...params} label="Secteurs visités" />
                              )}
                              renderOption={(props, option) => (
                                  <li {...props} key={option.ID_Secteur}>
                                      {option.Secteur?.toString()}
                                  </li>
                              )}
                            />

                              <Autocomplete
                                  disabled={ended}
                                  multiple
                                  options={superviseurs}
                                  getOptionLabel={(option) => option.Nom?.toString() || ''}
                                  value={superviseur}
                                  sx={inputStyles}
                                  onChange={(event, value) => {
                                      setSuperviseur(value);
                                  }}
                                  isOptionEqualToValue={(option, value) => option.ID_User === value.ID_User}
                                  renderInput={(params) => (
                                      <TextField {...params} label="Superviseur" />
                                  )}
                                  renderOption={(props, option) => (
                                      <li {...props} key={option.id}>
                                          {option.Nom?.toString()}
                                      </li>
                                  )}
                              />

                              <Autocomplete
                                  disabled={ended}
                                  multiple
                                  options={techniciens}
                                  getOptionLabel={(option) => option.Nom?.toString() || ''}
                                  value={technicien}
                                  sx={inputStyles}
                                  onChange={(event, value) => {
                                      setTechnicien(value);
                                  }}
                                  isOptionEqualToValue={(option, value) => option.ID_User === value.ID_User}
                                  renderInput={(params) => (
                                      <TextField {...params} label="Technicien" />
                                  )}
                                  renderOption={(props, option) => (
                                      <li {...props} key={option.ID_User}>
                                          {option.Nom?.toString()}
                                      </li>
                                  )}
                              />

                            </>
                            :
                            <Box mb={2}>
                              <Typography variant='h5'>Produits A Utilisés :</Typography>
                            <Typography ml={2}>Dératisation : {selectedDeratisations.map((pr)=>(pr.nom)).join(', ')}</Typography>
                            <Typography ml={2}>DESINSECTISATION : {selectedDesinsectisations.map((der)=>(der.nom)).join(', ')}</Typography>
                            <Typography ml={2}>DERAPTILISATION : {selectedDeraptilisation.map((der)=>(der.nom)).join(', ')}</Typography>
                            </Box>
                            
                            
                            }

                              {parseInt(user.id_role) === 2 ? selectedDeratisations.map((der,index)=>(
                                <>
                                <TextField
                                required
                                label={"Numéro de Post Installer Avec "+der.nom+" Interne"}
                                fullWidth
                                value={derInstaler[index] ? derInstaler[index].Interne : 0}
                                onChange={(e) => {
                                  const newderInstaler = [...derInstaler];
                                  newderInstaler[index] = { ...newderInstaler[index], products: der.id, Interne: e.target.value };
                                  setderInstaler(newderInstaler);
                                }}  
                                id="location"
                                type='number'
                                name="location"
                                variant="outlined"
                                sx={inputStyles}
                                disabled={ended}
                            />
                            <TextField
                            required
                            label={"Numéro de Post Installer Avec "+der.nom+" Externe"}
                            fullWidth
                            value={derInstaler[index] ? derInstaler[index].Externe : 0}
                            onChange={(e) => {
                              const newderInstaler = [...derInstaler];
                              newderInstaler[index] = { ...newderInstaler[index], products: der.id, Externe: e.target.value };
                              setderInstaler(newderInstaler);
                            }}  
                            id="location"
                            type='number'
                            name="location"
                            variant="outlined"
                            sx={inputStyles}
                            disabled={ended}
                        />
                        </>
                              )) : null}

                              {parseInt(user.id_role) === 2 ? selectedDesinsectisations.map((des, index) => (
                                <>
                                  <TextField
                                    required
                                    label={"Numéro de Piége Installer Avec " + des.nom + ' 20 W'}
                                    fullWidth
                                    value={desInstaler[index] ? desInstaler[index].num20W : 0}
                                    onChange={(e) => {
                                      const newdesInstaler = [...desInstaler];
                                      newdesInstaler[index] = { ...newdesInstaler[index], products: des.id, num20W: e.target.value };
                                      setdesInstaler(newdesInstaler);
                                    }}  
                                    id="location"
                                    type='number'
                                    name="location"
                                    variant="outlined"
                                    sx={inputStyles}
                                    disabled={ended}
                                  />
                                  <TextField
                                    required
                                    label={"Numéro de Piége Installer Avec " + des.nom + ' 40 W'}
                                    fullWidth
                                    value={desInstaler[index] ? desInstaler[index].num40W : 0}
                                    onChange={(e) => {
                                      const newdesInstaler = [...desInstaler];
                                      newdesInstaler[index] = { ...newdesInstaler[index], products: des.id, num40W: e.target.value };
                                      setdesInstaler(newdesInstaler);
                                    }}  
                                    id="location"
                                    type='number'
                                    name="location"
                                    variant="outlined"
                                    sx={inputStyles}
                                    disabled={ended}
                                  />
                                </>
                              )) : null}


                              {parseInt(user.id_role) === 2 ? selectedDeraptilisation.map((dera,index)=>(
                                                                <TextField
                                                                required
                                                                label={"Numéro de "+dera.nom+" consommé"}
                                                                fullWidth
                                                                value={deraInstaler[index] ? deraInstaler[index].num : 0}
                                                                onChange={(e) => {
                                                                  const newDeraInstaler = [...deraInstaler];
                                                                  newDeraInstaler[index] = { products: dera.id, num: e.target.value };
                                                                  setderaInstaler(newDeraInstaler);
                                                                }}  
                                                                id="location"
                                                                type='number'
                                                                name="location"
                                                                variant="outlined"
                                                                sx={inputStyles}
                                                                disabled={ended}
                                                            />
                              )) : null}
                </Box>
                </DialogContent>
                <DialogActions sx={{display:"flex" , flexWrap:"wrap" , justifyContent:"center"}}>
                {selectedEvent && (
                    <>
                    {parseInt(user.id_role) === 1 ?
                    <>
                        <Button onClick={handleDeleteEvent} sx={{ ...ButtonStyles, bgcolor: '#d32f2f', '&:hover': { bgcolor: '#c62828' } , width:'120px'}}>
                            Supprimer
                        </Button>
                        </>
                        : null}
                       
                                              <Button onClick={()=>navigate('/fcp/'+selectedEvent.id)} sx={ButtonStyles}>
                                              fcp
                                          </Button>
                                          <Button onClick={()=>navigate('/fct/'+selectedEvent.id)} sx={ButtonStyles}>
                                              fct
                                          </Button>
                                          <Button onClick={()=>navigate('/AuditTable/'+selectedEvent.id)} sx={{...ButtonStyles , width:"160px"}}>
                                              Audit Form
                                          </Button>
                                          <Button onClick={()=>navigate('/AuditForm/'+selectedEvent.id)} sx={{...ButtonStyles , width:"160px"}}>
                                            Audit Question
                                          </Button>
                                          {parseInt(user.id_role) !== 2 ? 
                                          <Button onClick={downloadPdf} sx={{...ButtonStyles , width:"190px"}}>
                       Télécharger l'attestation
                   </Button>
                   :null}
                                           </>
                    )}

                <Button onClick={handleDialogClose} sx={ButtonStyles}>
                        Annuler
                    </Button>
                    {!isclient ? 

                                        <Button onClick={handleSaveEvent} sx={ButtonStyles}>
                        {selectedEvent ? parseInt(user.id_role) ===  1 ? 'Modifier' : 'Affectuer' : 'Ajouter'}
                    </Button>
                    :null}
                </DialogActions>
            </Dialog>
        </Box>

        <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirm}
      />



        {errorMessages.map((message, index) => (
  <Snackbar
    key={index}
    open={opener}
    autoHideDuration={5000}
    onClose={handleCloseer}
    sx={{marginBottom: `${index * 55}px`}}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
  >
    <Alert
      onClose={handleCloseer}
      severity="error"
      variant="filled"
      sx={{ width: '100%' }}
    >
      {message}
    </Alert>
  </Snackbar>
))}
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
                <Alert
                onClose={handleClose}
                severity='success'
                variant="filled"
                sx={{ width: '100%' }}
                >

          {message}

                </Alert>
            </Snackbar>



        {/* pdf */}
        <div>
<div
  ref={certificateRef}
  id='containerpdf'
  style={{
    padding: '20px',
    width: '297mm',
    height: '210mm',
    border: '1px solid #ddd',
    marginBottom: '20px',
    boxSizing: 'border-box',
    backgroundImage: 'url(./bg.png)',
    backgroundSize: 'contain',
    position: 'absolute',
    left: '-9999px',
  }}
>
  <div style={{ textAlign: 'center', marginBottom: '5px', marginTop: '25px' }}>
    <div style={{ marginLeft: '50px', display: 'flex' }}>
      <img src='/logo.png' alt='Logo' style={{ height: '60px' }} />
    </div>
    <h1 style={{fontFamily:"Yeseva One" , fontSize:42 , marginBottom:"0px"}}>ATTESTATION</h1>
    <p style={{fontFamily:"Open Sans"}}>
      Nous soussignés, société <b>PESTIMA</b> certifions que<br />
      Le traitement de dératisation, désinsectisation et déreptilisation a été effectué dans l'ensemble des locaux de :
    </p>
    <h2 style={{fontFamily:"Yeseva One" , color:"#C00000" , fontSize:26 , margin:"5px 0px"}}>{selectedEvent ? selectedEvent.title : null}</h2>
  </div>
  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <div style={{ display: 'flex', justifyContent: 'space-around', width: '90%' ,columnGap:"200px" }}>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"70px" , marginBottom:"0px", marginTop:"10px",fontFamily:"Open Sans"}}>DERATISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454" , fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedDeratisations.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
          
          
        </div>
      </div>
      <div style={{ flex: '1', textAlign: 'center' }}>
        <h3 style={{marginRight:"50px" , marginBottom:"0px",marginTop:"10px" ,fontFamily:"Open Sans"}}>DESINSECTISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' }}>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedDesinsectisations.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', width: '100%' }}>
      <div style={{ textAlign: 'center' }}>
        <h3 style={{marginRight:"20px" , marginBottom:"0px" , fontFamily:"Open Sans" , marginTop:2}}>DERAPTILISATION</h3>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', textAlign: 'left' , columnGap:"80px" }}>
          <p style={{margin:"0px" , marginBottom:"2px" , fontFamily:"Carlito", color:"#545454", fontSize:14 , fontWeight:800}}>Produits utilisés :</p>
          <p style={{margin:"0px" , marginBottom:"2px", fontFamily:"Carlito" , color:"#545454", fontSize:14 , fontWeight:800}}>Matière active :</p>
          {
            selectedEvent ? selectedDeraptilisation.map((pr)=>(
                <>
                <p style={{margin:"0px"}}>{pr.nom}</p>
                <p style={{margin:"0px" , width:'fit-content' , textWrap:'nowrap'}}>{pr.matiereActive.map(item => item.Matieres_actives).join(", ")}</p>
                </>
            )) 
            : null
          }
        </div>
      </div>
    </div>
  </div>
  <div style={{ textAlign: 'center', marginTop: '10px' }}>
    <p style={{margin:"0px" , fontWeight:"700"}}>Ce certificat est délivré pour servir et valoir ce que de droit.</p>
    <img src={divider} width="60px"/>
    <p style={{margin:"0px" , fontWeight:"700"}}>Validité:</p>
    <p style={{margin:"0px" , fontWeight:"700" , color:"#C00000"}}>{selectedEvent ? selectedEvent.validite == 30 ? "1 MOIS" : selectedEvent.validite == 15 ? "15 JOURS" : selectedEvent.validite == 7 ? "7 JOURS" : null : null}</p>
  </div>
  <div style={{display:"flex" , flexDirection:"column" , alignItems:"center" , position:"absolute" , right:"100px" , top:"600px"}}>
  <p style={{margin: "0px",fontFamily:"Open Sans" , fontSize:14}}><b style={{color:"#C00000"}}>FAIT À :</b> {`CASABLANCA`} <b style={{color:"#C00000"}}>LE : </b>{formattedDate}</p>
  <p style={{margin:"0px",fontFamily:"Open Sans" , color:"#808080",fontSize:14 , fontWeight:700}}>DIRECTION </p>
  <img src={cacher} style={{marginTop:"5px"}}/>
  </div>
</div>


    </div>

        </>
    );
}

export default CalendarPage;
